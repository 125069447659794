import React, { useState } from "react";

// Importing Redux library
import {
    useMutation
} from "@apollo/client";
import {
    Avatar,
    Button,
    Container,
    CssBaseline,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Navigationbar from "./navigationbar.js";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useForm } from "react-hook-form";
import { FORGOT_PASSWORD } from "../graphql/mutation.js";
import { isValidEmail } from "../utility.js";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "1px 0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "16px",
    padding: theme.spacing(8),
    background: theme.palette.background.paper,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
  },
  form: {
    width: "111%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: theme.palette.primary.main,
    borderRadius: "22px",
    textTransform: "uppercase",
    fontWeight: "bold",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  textInput: {
    borderColor: theme.palette.success.light,
    "& input": {
      padding: "12px",
    },
  },
}));

export default function forgotPassword() {
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("1");

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // Using usedispatch hook
  const dispatch = useDispatch();

  const classes = useStyles();
  const { register, handleSubmit, control } = useForm();
  const history = useHistory();

  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted(res) {
      alert(res.forgotPassword?.message)
      setTimeout(() => history.push("/login"), 2000);
    },
    onError(e) {
      alert(JSON.parse(JSON.stringify(e))?.message);
      console.log("--dfd", JSON.parse(JSON.stringify(e)));
    },
  });

  const forgotPasswordApi = async () => {
    if (!validateInputs) {
      return;
    }
    const body = { email, userType: parseInt(userType) };
    try {
      forgotPassword({
        variables: { ...body },
      })
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const onClickSubmit = (data) => {
    console.log("calling");
    forgotPasswordApi();
  };
  const onError = (errors, e) => {
    console.log("errors", errors, "e", e);
  };

  const validateInputs = () => {
    if (!email) {
      alert("Needs Email");
      return false;
    }
    if (!isValidEmail(email)) {
      alert("Invalid Email");
      return false;
    }
    if (!userType) {
      alert("Needs User Type");
      return false;
    }
    return true;
  };
  const signupPageUrl =
    parseInt(userType) == 2 ? "/res_signup" : "/customer_signup";
  return (
    <>
      <Navigationbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" className="signIn">
            Forgot Password?
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onClickSubmit, onError)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              // , { required: true }
              inputRef={{ ...register("email") }}
              // required
              fullWidth
              id="email"
              className="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              // style={classes.textInput}
              inputProps={{ className: classes.textInput }}
            />
            <FormControl component="fieldset">
              <RadioGroup
                // aria-label="gender"
                name="controlled-radio-buttons-group"
                defaultValue={"1"}
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="customer"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="restaurant"
                />
              </RadioGroup>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
            <Grid container>
              <Grid item>
                <Link href={signupPageUrl} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
