/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Typography,
    Box,
    Grid,
    Hidden,
    Card,
    CardActions,
    CardActionArea,
    CardContent,
    List,
    ListItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveOutlined from "@material-ui/icons/SaveOutlined";
import { orange, teal } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Navigationbar from "./navigationbar";
import { addDishToCart, getAllResList, updateResProfile } from "../app/reducers/mainSlice";
import { capsStrFirstChar } from "../utility";
// import "./styles.css";
import ResDishCard from "./ResDishCard";
import CartDialog from "./CartDialog";
import { useRouteQuery } from "./resetPassword";

const ColorButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: teal[500],
        "&:hover": {
            backgroundColor: teal[700],
        },
    },
}))(Button);

const useStyles = makeStyles({
    gridContainer: {
        display: "flex",
        paddingLeft: "40px",
        paddingRight: "40px",
        overflow: "auto",
        justifyContent: "start",
        flexWrap: "wrap",
    },
    container: {
        marginTop: "80px",
    },
    table: {
        borderCollapse: 'collapse',
        borderWidth: 1,
        borderColor: 'black'
    },
    tableContainer: {
        padding: 5
    },
    flexContainerBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

// Function to generate time slots from 00:00 to 23:30
const generateTimeSlots = () => {

    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours, 10);
        const suffix = hour >= 12 ? 'PM' : 'AM';
        const adjustedHour = hour % 12 === 0 ? 12 : hour % 12;
        return `${adjustedHour}:${minutes} ${suffix}`;
    };

    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            // Format the time string to include leading zeros
            const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            slots.push({
                time,
                timeFormat: convertTo12HourFormat(time)
            });
        }
    }
    return slots;
};

const timeSlots = generateTimeSlots();

const ScheduleTable = (props) => {

    const mainReducer = useSelector((state) => state.mainReducer);
    const { resProfile, token } = mainReducer;

    const history = useHistory();

    const classes = useStyles();
    const dispatch = useDispatch();

    const query = useRouteQuery();
    const stripe_account_success = query.get('stripe_account_success');
    const [stripeAccountSuccess, setStripeAccountSuccess] = useState('')
    if (stripe_account_success != stripeAccountSuccess) {
        //should execute only once
        setStripeAccountSuccess(stripe_account_success);
    }

    useEffect(() => {

        const saveStripeSuccessStatus = async () => {
            const body = {
                res_id: resProfile?._id,
                stripeAccountLinked: 1,
                stripeAccountId: stripeAccountSuccess
            };
            const headers = {
                "x-access-token": token,
            };
            console.log("body", body);
            try {
                const url = "/restaurants/stripe_linked";
                const res = await axios.put(url, body, { headers });
                alert(`Your stripe account has been linked and setup. Now please setup your weekly schedule!`)
            } catch (err) {
                console.log(err);
            }
        }

        if (resProfile && token && stripeAccountSuccess) {
            saveStripeSuccessStatus();
        }

    }, [resProfile, token, stripeAccountSuccess])



    // Array of days for the table headers
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const [isDraggingJ, setIsDraggingJ] = useState(-1);
    const [dragStart, setDragStart] = useState(null);
    const [dragEnd, setDragEnd] = useState(null);
    const [selectedWhileDragging, setSelectedWhileDragging] = useState({})
    const [selectedSlots, setSelectedSlots] = useState({});
    const [highlightedDay, setHighlightedDay] = useState(null);


    const getRandomLightColor = () => {
        const hue = Math.floor(Math.random() * 360);
        const saturation = Math.floor(Math.random() * (100 - 50) + 50); // 50-100%
        const lightness = Math.floor(Math.random() * (100 - 70) + 70); // 70-100% for lighter colors
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const handleMouseDown = (time, day, i, j) => {
        setIsDraggingJ(j);
        setDragStart({ time, day });
        setHighlightedDay(day); // Highlight the column on drag start

        selectedWhileDragging[`${i}_${j}`] = true
        setSelectedWhileDragging({ ...selectedWhileDragging })
    };

    const handleMouseEnter = (time, day, i, j) => {
        if (isDraggingJ > -1 && dragStart.day === day) {
            if (new Date(`01/01/2020 ${time}`) > new Date(`01/01/2020 ${dragStart.time}`)) {
                setDragEnd({ time, day });
            }
            if (isDraggingJ == j) {
                selectedWhileDragging[`${i}_${j}`] = true
                setSelectedWhileDragging({ ...selectedWhileDragging })
            }
        }
    };

    const handleMouseUp = () => {
        if (dragStart && dragEnd && dragStart.day === dragEnd.day) {
            // Ensure dragEnd is later than dragStart before setting the slot
            if (new Date(`01/01/2020 ${dragEnd.time}`) > new Date(`01/01/2020 ${dragStart.time}`)) {
                const daySlots = selectedSlots[dragStart.day] || [];
                const newSlot = { from: dragStart.time, to: dragEnd.time, color: getRandomLightColor() };
                const updatedSlots = mergeSlots(daySlots, newSlot);
                let finalSlots = { ...selectedSlots, [dragStart.day]: updatedSlots }
                Object.keys(finalSlots).forEach(k => {
                    finalSlots[k] = finalSlots[k].filter(slot => slot.from && slot.to)
                })
                setSelectedSlots(finalSlots);
            }
        }
        setIsDraggingJ(-1);
        setDragEnd(null);
        setHighlightedDay(null); // Clear highlights and dragging state
    };

    const handleTouchStart = (e, time, day, i, j) => {
        e.preventDefault();  // Prevent scrolling when touching
        handleMouseDown(time, day, i, j);
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        if (isDraggingJ > -1) {
            const touch = e.touches[0];
            const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);
            if (targetElement && targetElement.getAttribute("data-time") && targetElement.getAttribute("data-day")) {
                const time = targetElement.getAttribute("data-time");
                const day = targetElement.getAttribute("data-day");
                const i = targetElement.getAttribute("data-i");
                const j = targetElement.getAttribute("data-j");
                handleMouseEnter(time, day, i, j);
            }
        }
    };

    const handleTouchEnd = () => {
        handleMouseUp();
    };

    const mergeSlots = (existing, newSlot) => {
        let merged = false;
        for (let i = 0; i < existing.length; i++) {
            if (existing[i].from <= newSlot.to && existing[i].to >= newSlot.from) {
                // Merge slots if there is overlap
                existing[i] = {
                    ...existing[i],
                    from: Math.min(existing[i].from, newSlot.from),
                    to: Math.max(existing[i].to, newSlot.to),
                };
                merged = true;
                break;
            }
        }
        if (!merged) {
            newSlot.color = getRandomLightColor();
            existing.push(newSlot);
        }
        return existing;
    };

    useEffect(() => {
        if (isDraggingJ == -1) {
            setSelectedWhileDragging({})
        }
    }, [isDraggingJ])

    const getBackgroundColor = (day, time, i, j) => {
        if (isDraggingJ > -1 && selectedWhileDragging[`${i}_${j}`]) {
            return '#ffeeba'
        }
        return (selectedSlots[day] || []).reduce((color, slot) => {
            if (slot.from <= time && slot.to >= time) {
                color = slot.color; // Set color if within slot
            }
            return color;
        }, 'transparent')
    }

    console.log(selectedSlots, 'selectedSlots', resProfile)

    const save = async () => {
        const body = {
            res_id: resProfile?._id,
            schedule: selectedSlots
        };
        const headers = {
            "x-access-token": token,
        };
        // console.log("body", body);
        try {
            const url = "/restaurants/schedule";
            const res = await axios.put(url, body, { headers });
            console.log("response", res);
            dispatch(updateResProfile(res.data));
            alert(`The schedule is saved successfully! Now you need to setup menu for your restaurant on next step.`)
            setTimeout(() => history.push("/restaurant/menu"), 500);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (resProfile?.schedule) {
            setSelectedSlots(JSON.parse(JSON.stringify(resProfile?.schedule)))
        }
    }, [resProfile?.schedule])


    return (
        <>
            <Navigationbar />
            <Box component="div" className={classes.container}>
                <div className={classes.tableContainer}>
                    <div className={classes.flexContainerBetween}>
                        <Typography component="h1" variant="h5">
                            Choose your Open Timings for Week
                        </Typography>
                        <div>
                            <ColorButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<SaveOutlined />}
                                onClick={save}
                            >
                                Save Schedule
                            </ColorButton>
                        </div>
                    </div>
                    <br />
                    <table className='schedule-table' onMouseLeave={() => {
                        setIsDraggingJ(-1);
                        setHighlightedDay(null); // Ensure highlight is removed if mouse leaves the table
                    }}>
                        <thead>
                            <tr>
                                {/* Empty header for the time column */}
                                <th>Time</th>
                                {daysOfWeek.map(day => (
                                    <th key={day} style={{
                                        backgroundColor: highlightedDay === day ? '#ffeeba' : 'transparent',
                                        padding: '5px'
                                    }}>{day}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {timeSlots.map((timeSlot, i) => (
                                <tr key={timeSlot.time}>
                                    <td>{timeSlot.timeFormat}</td>
                                    {daysOfWeek.map((day, j) => (
                                        <td key={day + timeSlot.time}
                                            data-time={timeSlot.time}
                                            data-day={day}
                                            onMouseDown={() => handleMouseDown(timeSlot.time, day, i, j)}
                                            onMouseEnter={() => handleMouseEnter(timeSlot.time, day, i, j)}
                                            onMouseUp={handleMouseUp}
                                            onMouseOver={() => {
                                                if (isDraggingJ == j) {
                                                    console.log(isDraggingJ, 'isDraggingJ')

                                                    selectedWhileDragging[`${i}_${j}`] = true
                                                    setSelectedWhileDragging({ ...selectedWhileDragging })
                                                }
                                            }}
                                            onTouchStart={(e) => handleTouchStart(e, timeSlot.time, day, i, j)}
                                            onTouchMove={handleTouchMove}
                                            onTouchEnd={handleTouchEnd}
                                            style={{
                                                backgroundColor: getBackgroundColor(day, timeSlot.time, i, j)
                                            }}
                                        >
                                            {/* Placeholder for data or dynamic content */}
                                            {timeSlot.timeFormat}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Box>
            {/* <pre>{JSON.stringify(selectedSlots, null, 2)}</pre> Debugging purposes */}
        </>
    );
};

export default ScheduleTable;
