import { applyMiddleware, createStore } from "redux";
import { reducers } from "./../src/app/store";

export const testStore = (initialState) => {
  const createStoreWithMiddleware = applyMiddleware()(createStore);
  return createStoreWithMiddleware(reducers, initialState);
};

export const capsStrFirstChar = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const random = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getOrderStatus = (num) => {
  const options = [
    "Received",
    "preparing",
    // "on the way",
    "Done",
    // "pickup_ready",
    // "picked_up",
    "cancelled",
  ];
  return options[num - 1];
};

export const getOrderStatusButton = (num) => {
  const options = [
    "Received",
    "Prepare",
    // "on the way",
    "FullFill",
    // "pickup_ready",
    // "picked_up",
    "cancelled",
  ];
  return options[num - 1];
};

export const isValidEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
  // console.log('Email validity', re.test(String(email).toLowerCase()));
  return re.test(String(email).toLowerCase());
};

// export const

export const getDefaultImageByFoodCat = (category) => {
  switch(category) {
    case 'appetizer':
      return '/menu/appetizer.jpeg';
    case 'main_course':
      return '/menu/main_course.jpg';
    case 'beverages':
      return '/menu/bevreges.jpg';
    case 'desserts':
      return '/menu/dessert.jpg';
    case 'alcohols':
      return '/menu/alcohols.jpg';
    default:
      return 'no_default_specified'
  }
}