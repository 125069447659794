import React, { useState } from "react";

// Importing Redux library
import {
    useMutation
} from "@apollo/client";
import {
    Avatar,
    Button,
    Container,
    CssBaseline,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Navigationbar from "./navigationbar.js";

import { IconButton, InputAdornment } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useForm } from "react-hook-form";
import { RESET_PASSWORD } from "../graphql/mutation.js";
import { isValidEmail } from "../utility.js";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(12),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "1px 0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "16px",
        padding: theme.spacing(8),
        background: theme.palette.background.paper,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.success.main,
    },
    form: {
        width: "111%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: theme.palette.primary.main,
        borderRadius: "22px",
        textTransform: "uppercase",
        fontWeight: "bold",
        "&:hover": {
            background: theme.palette.primary.dark,
        },
    },
    textInput: {
        borderColor: theme.palette.success.light,
        "& input": {
            padding: "12px",
        },
    },
}));

import { useLocation } from 'react-router-dom';

export function useRouteQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function resetPassword() {
    const [email, setEmail] = useState("");
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
    const [password, setPassword] = useState("");
    const [resetToken, setResetToken] = useState("");
    const query = useRouteQuery();
    const userType = query.get('userType');
    if(query.get('email') != email) {
        setEmail(query.get('email')) 
    }
    if(query.get('reset_token') != resetToken) {
        setResetToken(query.get('reset_token')) 
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    // Using usedispatch hook
    const dispatch = useDispatch();

    const classes = useStyles();
    const { register, handleSubmit, control } = useForm();
    const history = useHistory();


    const [resetPassword] = useMutation(RESET_PASSWORD, {
        onCompleted(res) {
            alert(res.resetPassword?.message)
            setTimeout(() => history.push("/login"), 2000);
        },
        onError(e) {
            alert(JSON.parse(JSON.stringify(e))?.message);
            console.log("--dfd", JSON.parse(JSON.stringify(e)));
        },
    });

    const resetPasswordApi = async () => {
        if (!validateInputs) {
            return;
        }
        const body = { email, password, userType: parseInt(userType), resetToken };
        try {
            resetPassword({
                variables: { ...body },
            })
        } catch (err) {
            console.log(err);
            alert(err);
        }
    };
    const onClickSubmit = (data) => {
        console.log("calling");
        resetPasswordApi();
    };
    const onError = (errors, e) => {
        console.log("errors", errors, "e", e);
    };

    const validateInputs = () => {
        if (!email) {
            alert("Needs Email");
            return false;
        }
        if (!isValidEmail(email)) {
            alert("Invalid Email");
            return false;
        }
        if (!password) {
            alert("Needs password");
            return false;
        }
        if (!userType) {
            alert("Needs User Type");
            return false;
        }
        if (!resetToken) {
            alert("Needs Reset Token");
            return false;
        }
        return true;
    };
    const signupPageUrl =
        parseInt(userType) == 2 ? "/res_signup" : "/customer_signup";
    return (
        <>
            <Navigationbar />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" className="signIn">
                        Reset Password
                    </Typography>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={handleSubmit(onClickSubmit, onError)}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            // , { required: true }
                            inputRef={{ ...register("email") }}
                            // required
                            fullWidth
                            id="email"
                            className="email"
                            label="Email Address"
                            name="email"
                            autoComplete="off" 
                            type="email"
                            value={email}
                            autoFocus
                            // style={classes.textInput}
                            inputProps={{ className: classes.textInput }}
                            disabled
                        />
                        <FormControl component="fieldset">
                            <RadioGroup

                                // aria-label="gender"
                                name="controlled-radio-buttons-group"
                                defaultValue={"1"}
                                value={userType}
                            >
                                <FormControlLabel
                                    value="1"
                                    control={<Radio disabled />}
                                    label="customer"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio disabled />}
                                    label="restaurant"
                                />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            inputRef={{ ...register("password") }}
                            fullWidth
                            name="password"
                            label="New password"
                            type={showPassword ? "text" : "password"} // Change type based on showPassword state
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="new-password"
                            InputProps={{
                                // Add InputProps to include the eye icon
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? (
                                                <VisibilityIcon />
                                            ) : (
                                                <VisibilityOffIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Reset Password
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link href={signupPageUrl} variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </>
    );
}
