import React from "react";
import NavigationBar from "./navigationbar.js";
import Dashboard from "./Dashboard.js";

const Home = () => {
  return (
    <>
      <NavigationBar />
      <Dashboard />
    </>
  );
};

export default Home;
