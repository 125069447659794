import React from "react";

// Importing Redux library
import {
    useMutation
} from "@apollo/client";
import {
    Button,
    makeStyles,
    Typography
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { onCustomerLogin, onResLogin } from "../app/reducers/mainSlice";
import { CUSTOMER_GOOGLE_LOGIN, RESTAURANT_GOOGLE_LOGIN } from "../graphql/mutation.js";

import {
    useGoogleLogin
} from "@react-oauth/google";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(12),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "1px 0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "16px",
        padding: theme.spacing(8),
        background: theme.palette.background.paper,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.success.main,
    },
    form: {
        width: "111%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: theme.palette.primary.main,
        borderRadius: "22px",
        textTransform: "uppercase",
        fontWeight: "bold",
        "&:hover": {
            background: theme.palette.primary.dark,
        },
    },
    textInput: {
        borderColor: theme.palette.success.light,
        "& input": {
            padding: "12px",
        },
    },
}));

export default function loginWithGoogle({ userType }) {

    // Using usedispatch hook
    const dispatch = useDispatch();

    const classes = useStyles();
    const history = useHistory();

    const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            console.log("codeResponse", codeResponse);
            const body = {
                access_token: codeResponse.access_token,
                scope: codeResponse.scope
            }
            userType == 1 ?
                customerGoogleLogin({
                    variables: { ...body },
                })
                :
                restaurantGoogleLogin({
                    variables: { ...body },
                })
        },
    });

    const [customerGoogleLogin] = useMutation(CUSTOMER_GOOGLE_LOGIN, {
        onCompleted(res) {
            console.log("da", res);
            dispatch(onCustomerLogin(res?.customerGoogleLogin));
            setTimeout(() => history.push("/customer"), 2000);
        },
        onError(e) {
            alert(JSON.parse(JSON.stringify(e))?.message);
            console.log("--dfd", JSON.parse(JSON.stringify(e)));
        },
    });


    const [restaurantGoogleLogin] = useMutation(RESTAURANT_GOOGLE_LOGIN, {
        onCompleted(res) {
            console.log("da", res);
            dispatch(onResLogin(res?.restaurantGoogleLogin));
            setTimeout(() => history.push("/res_profile"), 2000);
        },
        onError(e) {
            alert(JSON.parse(JSON.stringify(e))?.message);
            console.log("--dfd", JSON.parse(JSON.stringify(e)));
        },
    });

    // const signInApi = async () => {
    //     if (!validateInputs) {
    //         return;
    //     }
    //     const body = { email, password };
    //     try {
    //         parseInt(userType) == 1
    //             ? customerLogin({
    //                 variables: { ...body },
    //             })
    //             : restaurantLogin({
    //                 variables: { ...body },
    //             });
    //     } catch (err) {
    //         console.log(err);
    //         alert(err);
    //     }
    // };
    // const onClickSubmit = (data) => {
    //     console.log("calling");
    //     signInApi();
    // };
    const onError = (errors, e) => {
        console.log("errors", errors, "e", e);
    };



    return (
        <>
            <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => loginGoogle()}
            >
                <Typography style={{fontSize: 14, fontWeight: 'bold', display: 'flex', alignItems: 'center', height: 30}}>
                    {userType == 1 ? 'Customer' : 'Merchant'} Sign In with Google
                </Typography>
            </Button>

        </>
    );
}
