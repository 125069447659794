/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import Dish1 from "../images/dish1.jpeg";
import Dish2 from "../images/dish2.jpeg";
import Dish3 from "../images/dish3.jpeg";
import Dish4 from "../images/dish4.jpeg";
import { getDefaultImageByFoodCat, random } from "../utility";

export default function MediaCard(props) {
  const { dish, res, onAddToCartClick = () => {} } = props;

  const imgList = [Dish1, Dish2, Dish3, Dish4];
  // console.log("====dish", dish)
  return (
    <Card sx={{ maxWidth: 250 }}>
      <img
        src={dish.dish_image ? dish.dish_image : getDefaultImageByFoodCat(dish.dish_category)}
        alt="Dish 123"
        style={{
          display: "block",
          margin: "auto",
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />

      <div
        style={{
          display: "flex",
          "align-items": "flex-start",
          "flex-direction": "column",
        }}
      >
        <CardContent>
          <Typography
            className="dishName"
            gutterBottom
            variant="h5"
            component="div"
          >
            {dish?.dish_name || "pizza"}
          </Typography>
          <Typography variant="body2" color="black">
            {dish.description || "italian dish"}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              color="black"
              style={{
                textDecoration:
                  res.discount && res.discount > 0 ? "line-through" : "none",
              }}
            >
              ${dish.dish_price || "5.00"}
            </Typography>
            &nbsp;
            {res.discount && res.discount > 0 ? (
              <>
                <Typography
                  variant="body3"
                  color="black"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  $
                  {dish.dish_price - (dish.dish_price * res.discount) / 100 ||
                    "5.00"}
                </Typography>
              </>
            ) : (
              ""
            )}
          </Box>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            id="addCartBtn"
            variant="outlined"
            onClick={() => onAddToCartClick(res, dish)}
          >
            Add to Cart
          </Button>
          {/* <Button size="small" variant="outlined">Check Menu</Button> */}
        </CardActions>
      </div>
    </Card>
  );
}
