import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Chip, withStyles } from '@material-ui/core';

import { orange, teal, red } from "@material-ui/core/colors";

const GreenChip = withStyles((theme) => ({
    root: {
        color: teal[500],
        borderColor: teal[500],
        "&:hover": {
            borderColor: teal[700],
        },
    },
}))(Chip);

const RedChip = withStyles((theme) => ({
    root: {
        color: red[500],
        borderColor: red[500],
        "&:hover": {
            borderColor: red[700],
        },
    },
}))(Chip);

const ScheduleChecker = ({ schedule }) => {
    const [currentDay, setCurrentDay] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [nextOpening, setNextOpening] = useState('');
    const NO_UPCOMING = `No upcoming openings found`

    useEffect(() => {
        const updateCurrentTime = () => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            setCurrentTime(`${hours}:${minutes}`);

            const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            setCurrentDay(days[now.getDay()]);
        };

        updateCurrentTime();
        const timer = setInterval(updateCurrentTime, 60000); // Update time every minute

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const checkIfOpen = () => {
            const timeBlocks = schedule[currentDay] || [];
            const open = timeBlocks.some(block => block.from <= currentTime && currentTime < block.to);
            setIsOpen(open);

            const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            let index = days.indexOf(currentDay);

            for (let block of timeBlocks) {
                if (block.from > currentTime) {
                    setNextOpening(`${currentDay} at ${formatTime(block.from)}`);
                    return;
                }
            }

            for (let i = 1; i <= 7; i++) {
                let nextDayIndex = (index + i) % 7;
                let nextDay = days[nextDayIndex];
                let nextDayBlocks = schedule[nextDay] || [];
                if (nextDayBlocks.length > 0) {
                    setNextOpening(`${nextDay} at ${formatTime(nextDayBlocks[0].from)}`);
                    return;
                }
            }

            setNextOpening(NO_UPCOMING);
        };

        checkIfOpen();
    }, [schedule, currentDay, currentTime]);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <h1>Restaurant Schedule</h1>
            <p>Current Time: {currentTime}</p>
            <p>Current Day: {currentDay}</p> */}
            {isOpen ?
                <GreenChip label="Open" variant="outlined" color='primary' />
                :
                <>
                    <RedChip label="Closed" variant="outlined" color='secondary' />
                    {nextOpening == NO_UPCOMING ?
                        <RedChip label={NO_UPCOMING} variant="outlined" color='secondary' />
                        :
                        <GreenChip label={`Available ${nextOpening}`} variant="outlined" color='primary' />
                    }
                </>
            }
        </div>
    );
};

ScheduleChecker.propTypes = {
    schedule: PropTypes.objectOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                from: PropTypes.string.isRequired,
                to: PropTypes.string.isRequired
            })
        )
    ).isRequired
};

export default ScheduleChecker;

const formatTime = time => {
    const [hours, minutes] = time.split(':');
    const hrs = parseInt(hours);
    const suffix = hrs >= 12 ? 'PM' : 'AM';
    const formattedHours = ((hrs + 11) % 12 + 1); // Converts 0-23 hour format into 1-12 hour format
    return `${formattedHours}:${minutes} ${suffix}`;
};